(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var stickFooter = function stickFooter(el) {
    var $el = $(el);
    var position = $el.css('position');
    var bottom = $el.css('bottom');
    var reposition = function reposition() {
        $('#fixed-placeholder').addClass('hide');
        $el.removeClass('fixed').css({
            position: position,
            bottom: bottom
        });

        var bodyHeight = $('body').height();
        var winHeight = $(window).height();

        if (bodyHeight < winHeight) {
            $('#fixed-placeholder').height($el.outerHeight()).removeClass('hide');
            $el.addClass('fixed').css({
                position: 'fixed',
                bottom: 0
            });
        }

        $el.show();
    };

    reposition();
    $(window).on('resize scroll reposition', reposition);
};

var datepicker = function datepicker() {
    $.fn.datepicker.dates.cn = {
        days: ["周日", "周一", "周二", "周三", "周四", "周五", "周六", "周日"],
        daysShort: ["日", "一", "二", "三", "四", "五", "六", "七"],
        daysMin: ["日", "一", "二", "三", "四", "五", "六", "七"],
        months: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
        monthsShort: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
        today: "今天",
        clear: "清除"
    };

    $('.datepicker').datepicker({
        autoclose: true,
        format: 'yyyy-mm-dd',
        todayBtn: true,
        todayHighlight: true,
        language: 'cn'
    });
};

var setModalMaxHeight = function setModalMaxHeight(element) {
    var $element = $(element);
    var $content = $element.find('.modal-content');
    var borderWidth = $content.outerHeight() - $content.innerHeight();
    var dialogMargin = $(window).width() < 768 ? 20 : 60;
    var contentHeight = $(window).height() - (dialogMargin + borderWidth);
    var headerHeight = $element.find('.modal-header').outerHeight() || 0;
    var footerHeight = $element.find('.modal-footer').outerHeight() || 0;
    var maxHeight = contentHeight - (headerHeight + footerHeight);

    $content.css({
        'overflow': 'hidden'
    });

    $element.find('.modal-body').css({
        'max-height': maxHeight,
        'overflow-y': 'auto'
    });
};

var setHeight = function setHeight() {
    $('.max-height > .modal').on('show.bs.modal', function () {
        $(this).show();
        setModalMaxHeight(this);
    });

    $(window).resize(function () {
        if ($('.max-height > .modal.in').length !== 0) {
            setModalMaxHeight($('.modal.in'));
        }
    });
};

var getRest = function getRest(arr, value, prop) {
    var _ref = arguments.length <= 3 || arguments[3] === undefined ? {} : arguments[3];

    var getRemoved = _ref.getRemoved;

    var rest = arr.slice();
    var removed = [];

    for (var i = 0; i < rest.length; i++) {
        if (prop && rest[i][prop] === value || !prop && rest[i] === value) {
            removed = rest.splice(i, 1);
            break;
        }
    }

    if (getRemoved) {
        return removed;
    }

    return rest;
};

var detect = {
    isMobile: function isMobile() {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        );
    }
};

exports.stickFooter = stickFooter;
exports.datepicker = datepicker;
exports.setHeight = setHeight;
exports.setModalMaxHeight = setModalMaxHeight;
exports.getRest = getRest;
exports.detect = detect;

},{}],2:[function(require,module,exports){
'use strict';

var _util = require('../modules/util');

// 确认删除模块
var delModal = $('#del-confirm');

delModal.on('hidden.bs.modal', function () {
    delModal.find('h4').html('');
    delModal.find('.confirm-btn').attr('class', 'tt-btn confirm-btn');
});

// test data
var testurl = 'http://mysafeinfo.com/api/data?list=humor_quotes&format=json&rows=1';
var count = 0;

// 点赞
/* $('a.praise').on('click', function(e) {
    const url = $(this).attr('href');
    const praised = $(this).find('.praised');
    const num = $(this).find('.num');
    const liked = parseInt(num.html());

    count++;

    $.getJSON(url, (data) => {
        if (data.length) {
            const isPraised = count % 2 === 0;

            if (isPraised) {
                praised.show();
                num.html(liked + 1);
            } else {
                praised.hide();
                num.html(liked - 1 < 0 ? 0 : liked - 1);
            }
        }
    });

    e.preventDefault();
}); */

// 删除
$('a.del-post').on('click', function () {
    // confirmDel('post');
});

// 回复
$('.post-comments').on('click', '.reply-comment', function () {
    var user = $(this).data('reply-user');
    var parent_id = $(this).data('reply-parent');
    if (user) {
        $('#post-comment-form .comment-content').focus().val('@[' + user + '] ');
        $('#post-comment-form .parent_id').val(parent_id);
    }
});

// 删除评论
$('.post-comments').on('click', '.del-comment', function () {
    // const item = $(this).closest('.item');
    //
    // $.getJSON(testurl, (data) => {
    //     item.slideUp();
    // });
    // confirmDel('comment', { id: 123 });
});

// 评论点赞
$('.post-comments').on('click', '.like-comment', function () {
    var num = $(this).find('span');
    var liked = parseInt(num.html());

    count++;

    $.getJSON(testurl, function (data) {
        var isLiked = count % 2 === 0;

        if (isLiked) {
            num.html(liked + 1);
        }
    });
});

// 发表评论
$('#post-comment-form .confirm-btn').on('click', function () {
    var url = $(this).closest('form').attr('action');
    var comment = $('textarea.comment-content');
    var commentval = comment.val().trim();
    var comments = $('.post-comments');
    var tpl = '';

    if (commentval !== '') {
        $.getJSON(url, function (data) {
            if (data.length) {
                data.forEach(function (val) {
                    var type = val.type ? '<span class="info type">' + val.type + '</span>' : '';
                    var avatar = '/assets/img/missing_face.png';

                    tpl += '\n                        <div class="tt-box md item">\n                            <div class="main-container">\n                                <div class="meta clearfix">\n                                    <div class="tt-user pull-left">\n                                        <a href="#"><img src="' + avatar + '" alt=""></a>\n                                        <a class="info" href="#">' + val.nm + '</a>\n                                        ' + type + '\n                                    </div>\n                                    <div class="opts pull-right">\n                                        <a href="#">编辑</a>\n                                        <a class="del-comment" href="javascript:;">删除</a>\n                                        <span>2016.12.26</span>\n                                        <a href="#post-comment-form">回复</a>\n                                        <a class="like-comment" href="javascript:;">支持 <span>0</span></a>\n                                    </div>\n                                </div>\n                                <div class="content">\n                                    ' + commentval + '\n                                </div>\n                            </div>\n                        </div>\n                    ';
                });
            }

            var locationhref = location.href;
            if (locationhref.indexOf('#') !== -1) {
                location.href = locationhref.split('#')[0] + '#post-comments';
            } else {
                location.href = locationhref + '#post-comments';
            }

            $(tpl).prependTo(comments).slideDown();
            comment.val('');
        });
    }
});

// 分享
if (_util.detect.isMobile()) $('span.icon-wechat').hide();
$('[data-shareto]').on('click', function () {
    $('.social-share').find('a.icon-' + $(this).data('shareto'))[0].click();
});

function confirmDel(type, data) {
    if (type === 'post') {
        delModal.find('h4').html('你确定要删除这篇文章么？');
        delModal.find('.confirm-btn').addClass('del-post-btn');
    } else if (type === 'comment') {
        delModal.find('h4').html('你确定要删除这条评论么？');
        delModal.find('.confirm-btn').addClass('del-comment-btn');
    }

    delModal.modal('show');
}

},{"../modules/util":1}]},{},[2]);
